/*global prodcat*/
(function(site, $) {
  var formCaptureObj = {}, linkCaptureObj = {};
  
  Drupal.behaviors.analyticsBehavior = {
    attached: 0,

    findElementIndex: function(arr, value) {
      return _.findIndex(arr, function(elem) {
        return elem === value;
      });
    },

    linkToPage: function() {
      window.open(linkCaptureObj.href, linkCaptureObj.target);
    },

    setLinkCapture: function(href, target) {
      linkCaptureObj.href = href;
      linkCaptureObj.target = target;
    },

    submitForm: function() {
      formCaptureObj.form.off('submit');
      formCaptureObj.form.trigger('submit');
    },

    stripOutMarkup: function(str) {
      return str.replace(/(<([^>]+)>)/ig, '');
    },

    // Accepts an array of PRODUCT_IDS, returns an array of positions
    getProductPositions: function(productIds) {
      var positions = [];
      var i;
      var j = productIds.length;
      if (window.hasOwnProperty('prodcat') && prodcat.hasOwnProperty('data') && prodcat.data.hasOwnProperty('pids')) {
        for (i = 0; i < j; i++) {
          positions.push(this.findElementIndex(prodcat.data.pids, productIds[i]));
        }
      }
      return positions;
    },

    // Examples of brand specific overrides for event handling

    addToCart: function(eventData) {
      site.track.addToCart(Object.assign({}, eventData));
    },

    addToFavorites: function(eventData) {
      site.track.addToFavorites(Object.assign({}, eventData));
    },

    removeFromCart: function(eventData) {
      site.track.removeFromCart(Object.assign({}, eventData));
    },

    // End examples brand specific overrides for event handling

    attach: function(context) {
      // all code here
      var self = this;
      var drupalAltImgSettings = Drupal.settings.analytics ? Drupal.settings.analytics.alt_image_tagging_enabled : false;
      var analyticsGnavSettings = Drupal.settings.analytics ? Drupal.settings.analytics.gnav_tagging_enabled : false;
      var drupalSkuUpdate = Drupal.settings.analytics ? Drupal.settings.analytics.sku_product_update : false;
      var eventObj = {};
      if (self.attached) {
        return;
      }

      // Track Brand Logo
      $('a.site-header__logo__link', context).on('click', function(event) {
        var obj;
        event.preventDefault();
        self.setLinkCapture($(this).attr('href'), '_self');
        obj = {
          event_name: 'logo_click',
          event_category: 'global',
          event_action: 'logo clicked'
        };
        site.track.evtLink(obj, self.linkToPage);
      });

      // Track Notify me button
      $('.js-add-to-waitlist').on('click', function() {
        var $targetElem = $(this).closest('.js-product');
        var prodId = $targetElem.data('product-id');
        var prodName = $targetElem.find('.product-full__name:first').text().trim();
        trackEvent('notify_me', 'ecommerce', 'notify me - request', prodName + ' - ' + prodId);
      });

      // Track Product Click
      $(document).on('click', '.js-product-brief a, .js-spp-link', function() {
        var $currentElem = $(this);
        var prodElem = $(this).parents('.js-product-grid-item, .js-quickshop');
        var prodId = prodElem.attr('data-product-id');

        if (typeof prodId !== 'undefined' && prodId !== '') {
          site.track.productClick({
            targetElem: $currentElem,
            product_id: [prodId]
          });
        }
      });

      // Track Quick Shop
      $(document).on('click', '.js-quickshop-launch-overlay', function () {
        var obj;
        var $targetElem = $(this);
        var catName = $('.content > article', context).attr('trackname');
        var prodElem = $targetElem.closest('.js-product-grid-item');
        var prodId = $targetElem.attr('data-product-id');
        var prodName = $.trim(self.stripOutMarkup(prodElem.find('.js-product-brief').find('.product-brief__name a').html()));
        obj = {
          event_label: [prodId],
          page_name: 'QV | ' + prodName,
          product_id: [prodId],
          product_catagory_name: [catName],
          product_price: [prodElem.find('.js-product-price').text().replace(/\s+/g, ' ').trim()]
        };
        var skuId = $targetElem.closest('.product-grid__item').find('.js-inv-status-list').attr('data-sku-base-id');
        if (drupalSkuUpdate && skuId) {
          var skuData = prodcat.data.getSku(skuId);

          if (skuData.SHADENAME !== '' && skuData.SHADENAME !== null) {
            obj.product_shade = [skuData.SHADENAME];
          }
          if (skuData.PRODUCT_SIZE !== '' && skuData.PRODUCT_SIZE !== null) {
            obj.product_size = [skuData.PRODUCT_SIZE];
          }
          obj.product_price = [skuData.PRICE.toString()];
          obj.product_product_code = [skuData.PRODUCT_CODE];
          obj.product_sku = [skuId];
          obj.product_upc = [skuData.UPC_CODE];
        }
        site.track.quickView(obj);
      });

      // Track Predictive Search Product Click and All Results Click
      $(window).on('OMNISEARCH', function() {
        var prods = $('.js-typeahead-wrapper a.js-spp-link');
        var term = $('input#search').val();
        prods.on('click', function(event) {
          event.preventDefault();
          var $prod = $(this);
          var $prodWrapper = $prod.closest('.js-product');
          var obj = {
            event_label: term,
            search_keyword: term,
            product_id: $prodWrapper.attr('data-product-id'),
            product_name: self.stripOutMarkup($prodWrapper.find('.product-brief__name a').text())
          };

          self.setLinkCapture($prod.attr('href'), '_self');
          site.track.evtAction('searchOneResultSelected', obj, self.linkToPage);
        });
      });

      // Track MPP Filters
      $('a.js-filter-trigger', context).on('click', function() {
        var elem = $(this);
        var obj = {
          event_label: elem.text().trim()
        };
        site.track.evtAction('filterProducts', obj);
      });

      // CHECKOUT EVENTS

      // Track guest user checkout
      $(document).on('click', '#checkout_signin_new_user-submit,#checkout_signin_guest_user-submit', function() {
        var obj = {};
        site.track.evtAction('checkoutGuestUser', obj);
      });

      // Track return user checkout
      $(document).on('click', '#checkout_signin-submit', function() {
        var obj = {};
        site.track.evtAction('checkoutReturnUser', obj);
      });

      // Track Payment Method
      $(document).on('checkout:panel:displayed', function() {
        $('#checkout_billing input.form-submit,#checkout_review input.place-order').on('click', function() {
          var payment_label = '';
          var payment_type = $('input[name=PAYMENT_TYPE]:checked, input[name=PAYMENT_METHOD]:checked ').val();
          if (payment_type === 'PayPal' || payment_type === 'PP') {
            payment_label = 'paypal';
          } else if (payment_type === 'Credit Card' || payment_type === 'PP_STRIPE_CC') {
            payment_label = 'creditcard';
          }
          var obj = {
            event_label: payment_label
          };
          site.track.evtAction('checkoutPaymentSelected', obj);
        });
      });

      // Track Paypal Express Check-out
      $('a.paypal-checkout').on('click', function() {
        var obj = {
          event_label: 'paypal'
        };
        site.track.evtAction('checkoutPaymentSelected', obj);
      });

      // END CHECKOUT EVENTS

      // Alt image tracking start
      if ($('body').hasClass('section-product') && drupalAltImgSettings) {
        trackAltImageTaggingClick();
      }

      // Track Alt image click - desktop and mobile
      function trackAltImageTaggingClick() {
        $(document).on('click', '.carousel-dots .slick-dots button', function() {
          if ($('.carousel-dots .slick-dots li').length > 1) {
            var $targetElem = $(this).closest('.carousel-dots');
            var altImageName = '';
            var prodDetails = buildAltImageProductName($targetElem);
            var bgImageUrl = $targetElem.siblings('.js-spp-carousel').find('.slick-current img').attr('src');
            if (bgImageUrl) {
              altImageName = buildAltImageFileName(bgImageUrl);
            }
            trackThumbnailClickEvent(altImageName, prodDetails[0], prodDetails[1], 'click');
          }
        });
      }

      // Build Alt image product name
      function buildAltImageProductName(targetElem) {
        var prodElem = targetElem.closest('.js-product');
        var prodId = prodElem.attr('data-product-id');
        var prodName = prodElem.find('.product-full__product-name').text();
        if (prodName) {
          prodName = prodName.replace(/\s+/g, ' ').trim();
        }
        return [prodName, prodId];
      }

      // Build Alt image file name
      function buildAltImageFileName(altImageUrl) {
        var altImageArray = altImageUrl.split('/');
        var altImageName = altImageArray[altImageArray.length - 1].split('.')[0];
        return altImageName;
      }

      // Track product thumbnail click event
      function trackThumbnailClickEvent(altImageName, prodName, prodId, eventType) {
        var obj = {
          'event_action': 'alt image - ' + altImageName + ' - ' + eventType,
          'event_label': prodName + ' - ' + prodId
        };
        site.track.productThumbnailClick(obj);
      }
      // Alt image tracking end

      // Gnav & Nav Tagging Start.
      if (analyticsGnavSettings) {
        $(document).on('click', 'input[type=checkbox]', function() {
          var $navElem = $(this);
          if ($navElem.is(':checked')) {
            var navLabel = $navElem.attr('name');
            var fmGnavCheck = $navElem.closest('.perfume-filter').hasClass('js-mpp-filter-content');
            if (($('body').hasClass('page-products-perfume') || $('body').hasClass('page-products-giftfinder')) && fmGnavCheck) {
              trackEvent('filters_and_sort_selection', 'filter & sort selection', 'filter - selected', 'filter - ' + navLabel);
            } else {
              trackEvent('filters_and_sort_selection', 'filter & sort selection', 'GNAV - filter - selected', 'filter - ' + navLabel);
            }
          }
        });
      }
      // Gnav & Nav Tagging End

      // trackEvent common function call start
      function trackEvent(eName, eCategory, eAction, elabel) {
        Object.assign(eventObj, {
          event_name: eName,
          event_category: eCategory,
          event_action: eAction,
          event_label: elabel
        });
        site.track.evtLink(eventObj);
        eventObj = {};
      }
      // trackEvent common function call end

      // Module open close clicks
      $(document).on('mousedown touchdown', '.js-samples-view-button', function () {
        var clickSense = $(this).closest('#samples-panel').find('.js-accordion-content').is(':visible');
        var evtLabel = $(this).text().trim();

        if (clickSense) {
          trackEvent('sample_module_close', 'samples', 'module_close', evtLabel);
        } else {
          trackEvent('sample_module_open', 'samples', 'module_open', evtLabel);
        }
      });

      // Tracking arrow clicks
      $(document).on('mousedown touchdown', '.slick-prev, .slick-next', function () {
        var $currentElem = $(this);
        var carouselName = $currentElem.closest('#samples-panel').find('.js-samples-accordion').text().trim();

        if (!($currentElem.hasClass('disabled') || $currentElem.hasClass('slick-disabled')) && carouselName) {
          trackEvent('sample_arrow_click', 'samples', 'arrow_click', carouselName);
        }
      });

      // Remove sample tracking
      $(document).on('click', '.sample-select-button', function () {
        var $currentElem = $(this);
        var $prodContainerElem = $currentElem.closest('.product__container, .js-product-details');
        var $targetElem = $prodContainerElem.length > 0 ? $prodContainerElem.first() : $currentElem;
        var $skuSelected = $targetElem.find('.sample-select');
        var skuId = $skuSelected.val();
        var $productNameElem = $targetElem.find('.product_name, .js-product-brief-title');
        var skuName = $productNameElem.length ? $productNameElem.first().text().trim() : '';
        var removeSample = $currentElem.hasClass('sample-select-button') ? !$skuSelected.prop('checked') : $currentElem.hasClass('selected');
        var $samplePageElem = $('body').attr('id') || $('body').attr('class');
        var separateSamplePage = ($samplePageElem && $samplePageElem.indexOf('sample') > -1) ? true : false;

        skuId = (skuId && skuId.toUpperCase().includes('SKU')) ? skuId : 'SKU' + skuId;
        if (removeSample) {
          trackEvent('samples', 'samples', 'samples_remove', skuName + ' - ' + skuId);
        } else if (separateSamplePage) {
          trackEvent('samples', 'samples', 'samples_added', skuName + ' - ' + skuId);
        }
      });

      self.attached = 1;
    }
  };
}(window.site || {}, jQuery));
